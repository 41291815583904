import {
  Flex,
} from '@chakra-ui/react';
import { capitalize, convertToLocalDateWithTime, getDateFromStr, todaysDate } from '../../utils/utils';
import { useState } from 'react';
import { DateInput } from './DateInput';
import { InputLabel, ModalButton, NumericInput, SelectInput } from './ModalComponents';
import { StyledModal } from './StyledModal';



export function EditLiftModal({ lift, liftTypes, isOpen, onClose, onDelete, onUpdate }) {
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [newDate, setNewDate] = useState('');
  const [newLiftType, setNewLiftType] = useState('');
  const [newWeight, setNewWeight] = useState(0);
  const [newReps, setNewReps] = useState(0);

  const handleDelete = async (e) => {
    if (lift.id) {
      setDeleting(true);
      await onDelete(lift.id);
      setDeleting(false);
      onClose();
    }
  }

  const resetState = () => {
    setNewDate('')
    setNewLiftType('')
    setNewWeight(0);
    setNewReps(0);
  }

  const handleUpdate = async (e) => {
    const { lift_type_info, ...updatedLift } = { ...lift };

    if (newLiftType) {
      var liftType;
      liftTypes.forEach(lt => {
        if (lt.id === newLiftType) {
          liftType = lt;
        }
      })

      if (liftType) {
        updatedLift.lift_type = liftType.name;
        updatedLift.lift_type_id = liftType.id;
      } else {
        console.warn(`No lift type for ${newLiftType}`);
      }
    }

    if (newWeight) {
      updatedLift.weight = newWeight;
    }

    if (newReps) {
      updatedLift.reps = newReps;
    }

    if (newDate) {
      const updatedDate = convertToLocalDateWithTime(newDate, 0);
      updatedLift.created_at = updatedDate;
    }

    if (JSON.stringify(lift) !== JSON.stringify(updatedLift)) {
      setUpdating(true);
      await onUpdate(updatedLift);
      setUpdating(false);
    }

    resetState();
    onClose();
  }

  const onModalClose = () => {
    resetState();
    onClose();
  }

  const onDateChange = (e) => {
    setNewDate(e.target.value);
  }

  const onLiftTypeChange = (e) => {
    setNewLiftType(e.target.value);
  }

  const onWeightChange = (e) => {
    const weight = parseFloat(e.target.value);
    if (!isNaN(weight)) {
      setNewWeight(weight);
    } else {
      console.warn(`Invalid weight value ${e.target.value}`);
    }
  }

  const onRepChange = (e) => {
    const reps = parseInt(e.target.value);
    if (!isNaN(reps)) {
      setNewReps(reps);
    } else {
      console.warn(`Invalid rep value ${e.target.value}`);
    }
  }

  const liftOptions = liftTypes.filter(lt => lt.active).map(lt => {
    return {
      value: lt.id,
      label: capitalize(lt.name),
    }
  });

  const modalTitle = 'Edit Set'
  const closeOnOverlayClick = !updating && !deleting;

  return <StyledModal
    title={modalTitle}
    body={
      <Flex flexDirection="column" gap={4}>
        <Flex align="center">
          <InputLabel name='Date' />
          <DateInput
            bg='coolGray.600'
            date={newDate || getDateFromStr(lift.created_at)}
            maxDate={todaysDate()}
            handleDateChange={onDateChange}
          />
        </Flex>

        <Flex align="center">
          <InputLabel name='Lift' />
          <SelectInput
            placeholderText='Select Lift'
            value={newLiftType || lift.lift_type_id}
            options={liftOptions}
            onChange={onLiftTypeChange}
          />
        </Flex>

        <Flex align="center">
          <InputLabel name='Weight' />
          < NumericInput value={newWeight || lift?.weight} onChange={onWeightChange} />
        </Flex>

        <Flex align="center">
          <InputLabel name='Reps' />
          < NumericInput value={newReps || lift?.reps} onChange={onRepChange} />
        </Flex>
      </Flex>
    }
    footer={
      <>
        <ModalButton
          mr={6}
          color='gray.100'
          loadingBg='error.800'
          bg='error.500'
          text='Delete'
          isLoading={deleting}
          isDisabled={updating || deleting}
          onClick={handleDelete}
        />
        <ModalButton
          mr={0}
          color='gray.100'
          loadingBg='primary.800'
          bg='primary.600'
          text='Update'
          isLoading={updating}
          isDisabled={updating || deleting}
          onClick={handleUpdate}
        />
      </>
    }
    isOpen={isOpen}
    closeOnOverlayClick={closeOnOverlayClick}
    onClose={onModalClose}
  />
}
