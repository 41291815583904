import {
  Box,
  IconButton,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import { ROUTES } from '../../config/routes';

import { FaHome, FaRegChartBar } from 'react-icons/fa';
import { IoIosSettings, IoMdLogOut } from 'react-icons/io';
import { ToastAlert } from '../common/ToastAlert';

function NavOption({ icon, onClick, title }) {
  return < Button
    color='gray.200'
    w="full"
    justifyContent="flex-start"
    variant="ghost"
    fontWeight='sem-bold'
    _hover={{}}
    _focus={{}}
    _active={{}}
    iconSpacing={6}

    // props
    leftIcon={icon}
    onClick={onClick}
  >

    {title}
  </Button >
}

export default function SideNav({ isOpen, onClose, btnRef }) {
  const navigate = useNavigate();
  const toast = useToast()
  const auth = useAuth();

  const onHomeV2Clicked = () => {
    onClose();
    navigate(ROUTES.MAIN)
  }

  const onLogoutClicked = async () => {
    const { error } = await auth.signOut();
    if (error) {
      toast({
        title: 'Issue logging out',
        status: 'error',
        position: 'top',
        duration: 2500,
        isClosable: false,
        render: () => <ToastAlert status='error' message='There was an issue logging out' />,
      })
    }
    navigate(ROUTES.HOME);
  }

  const onLiftTypesClicked = () => {
    onClose();
    navigate(ROUTES.LIFT_TYPE_LIST)
  }

  const onChartsClicked = () => {
    onClose();
    navigate(ROUTES.CHART);
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay bg="transparent" />
        <DrawerContent
          bg='primary.900'
          borderRight='1px'
          borderColor='gray.700'
          maxWidth={{ base: "3lg", sm: "3lg", md: "3lg", lg: "xl", xl: "xl" }}
        >

          <Box h='lg' w='lg' display='flex' alignItems='center' justifyContent='center' p={4}>
            <IconButton
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="unstyled"
              color="white"
              onClick={onClose}
            />
          </Box>

          <DrawerBody>
            <VStack spacing={4}>
              <NavOption title='Home' onClick={onHomeV2Clicked} icon={<FaHome />} />
              <NavOption title='Charts' onClick={onChartsClicked} icon={<FaRegChartBar />} />
              <NavOption title='Lift Settings' onClick={onLiftTypesClicked} icon={<IoIosSettings />} />
            </VStack>
          </DrawerBody>


          <DrawerFooter flexDirection="column" alignItems="start" justifyContent="start">
            <NavOption title='Log Out' onClick={onLogoutClicked} icon={<IoMdLogOut />} />
          </DrawerFooter>

        </DrawerContent>
      </Drawer>
    </>
  );
}
