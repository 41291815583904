import React, { useState } from 'react';
import { Flex, Box, Select, useTheme, FormLabel } from '@chakra-ui/react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useDataManager } from '../data/useDataManager';

const DATE_INTERVAL = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
};

const INTERVAL_OPTIONS = [
  <option key={DATE_INTERVAL.DAILY} value={DATE_INTERVAL.DAILY}>Daily</option>,
  <option key={DATE_INTERVAL.WEEKLY} value={DATE_INTERVAL.WEEKLY}>Weekly</option>,
  <option key={DATE_INTERVAL.MONTHLY} value={DATE_INTERVAL.MONTHLY}>Monthly</option>,
];

const CHART_TYPE = {
  MAX_REP: 'max_rep',
  VOLUME: 'volume',
}

const CHART_OPTIONS = [
  <option key='volume' value={CHART_TYPE.VOLUME}>Volume</option>,
  <option key='max-rep' value={CHART_TYPE.MAX_REP}>Max Rep</option>,
]

const dateFormatter = (dateString, interval) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);

  if (interval === DATE_INTERVAL.MONTHLY) {
    return date.toLocaleDateString('en-US', { month: 'numeric', year: '2-digit' });
  }

  return date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: '2-digit' });
};

const defaultYTicks = [0, 10, 20, 30];
function generateYticks(minY, maxY) {
  if (!isFinite(Math.abs(minY)) || !isFinite(Math.abs(maxY))) {
    return defaultYTicks;
  }

  const range = maxY - minY;

  const step = Math.ceil((range / 3) / 10) * 10
  const start = Math.floor(minY / 10) * 10

  const ticks = [];
  for (let i = 0; i < 4; i++) {
    ticks.push(start + i * step);
  }
  return ticks;
}

const generateXTicks = (minX, maxX, interval) => {
  const xTicks = [];
  const date = new Date(minX);
  const endDate = new Date(maxX);

  switch (interval) {
    case DATE_INTERVAL.DAILY:
      while (date <= endDate) {
        xTicks.push(date.getTime());
        date.setDate(date.getDate() + 1);
      }
      break;
    case DATE_INTERVAL.WEEKLY:
      while (date <= endDate) {
        xTicks.push(date.getTime());
        date.setDate(date.getDate() + 7);
      }
      break;
    case DATE_INTERVAL.MONTHLY:
      while (date <= endDate) {
        xTicks.push(date.getTime());
        date.setMonth(date.getMonth() + 1);
      }
      break;
    default:
      break;
  }

  return xTicks;
};


const ChartPage = () => {
  const { lifts, liftTypes } = useDataManager();
  const [selectedLiftType, setSelectedLiftType] = useState('squat');
  const [selectedReps, setSelectedReps] = useState('');

  const [chartType, setChartType] = useState(CHART_TYPE.VOLUME);
  const [dateInterval, setDateInterval] = useState(DATE_INTERVAL.DAILY);

  const matchingLifts = lifts
    .filter((lift) => (selectedLiftType ? lift.lift_type === selectedLiftType : true));

  // for chart
  matchingLifts.forEach(l => {
    l.created_date = new Date(l.created_at).getTime();
  });

  const repOptions = Array.from(new Set(matchingLifts.map((lift) => lift.reps))).sort((a, b) => b - a);

  const handleLiftTypeChange = (event) => {
    const liftType = event.target.value;
    setSelectedLiftType(liftType);

    let noRepMatch = true;
    for (let i = 0; i < lifts.length; i++) {
      let l = lifts[i];
      if (l.lift_type === liftType && selectedReps === `${l.reps}`) {
        noRepMatch = false;
        break;
      }
    }

    if (noRepMatch) {
      setSelectedReps('');
    }
  };

  const handleRepsChange = (e) => {
    setSelectedReps(e.target.value);
  };

  const handleDateIntervalChange = (e) => {
    setDateInterval(e.target.value);
  }

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  }

  const liftOptions = liftTypes.filter(lt => lt.active).map(lt => lt.name).map(liftType => {
    return <option key={liftType} value={liftType}>
      {liftType}
    </option>
  });

  return (
    <Flex width="100%" justifyContent="center">
      <Box maxWidth="800px" width="95%" height="400px" marginTop="20">
        <Flex justifyContent="flex-start" mb={8} mt={8} gap={5}>

          <Box>
            <FormLabel mb={3} fontSize='xs' color='gray.200'>Chart</FormLabel>
            <Select
              color='gray.300'
              borderColor='gray.500'
              value={chartType}
              onChange={handleChartTypeChange}
            >
              {CHART_OPTIONS}
            </Select>
          </Box>

          <Box>
            <FormLabel mb={3} fontSize='xs' color='gray.200'>Interval</FormLabel>
            <Select
              color='gray.300'
              borderColor='gray.500'
              value={dateInterval}
              onChange={handleDateIntervalChange}
            >
              {INTERVAL_OPTIONS}
            </Select>
          </Box>

          <Box>
            <FormLabel mb={3} fontSize='xs' color='gray.200'>Lift</FormLabel>
            <Select
              color='gray.300'
              borderColor='gray.500'
              value={selectedLiftType}
              onChange={handleLiftTypeChange}
            >
              {liftOptions}
            </Select>
          </Box>

          {chartType === CHART_TYPE.MAX_REP &&
            <Box>
              <FormLabel mb={3} fontSize='xs' color='gray.200'>Reps</FormLabel>
              <Select
                color='gray.300'
                borderColor='gray.500'
                value={selectedReps}
                onChange={handleRepsChange}>

                <option value="">All</option>
                {repOptions.map((rep) => (
                  <option key={rep} value={rep}>
                    {rep}
                  </option>
                ))}
              </Select>
            </Box>
          }

        </Flex>

        {chartType === CHART_TYPE.MAX_REP && <MaxRepChart lifts={matchingLifts} dateInterval={dateInterval} repTarget={selectedReps} />}
        {chartType === CHART_TYPE.VOLUME && <VolumeChart lifts={matchingLifts} dateInterval={dateInterval} />}
      </Box>
    </Flex>
  );
};

const aggregateMaxRepData = (data, interval) => {
  const aggregatedData = [];
  const aggregationMap = {};

  data.forEach(item => {
    const date = new Date(item.created_date);
    let key;

    switch (interval) {
      case 'daily':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'weekly':
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        key = startOfWeek.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'monthly':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' });
        break;
      default:
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
    }

    if (!aggregationMap[key]) {
      aggregationMap[key] = { weight: 0, estimated_one_rep_max: 0 };
    }

    const estimatedOneRepMax = Math.round(item.weight / (1.0278 - 0.0278 * item.reps));

    if (item.weight > aggregationMap[key].weight) {
      aggregationMap[key].weight = item.weight;
    }

    if (estimatedOneRepMax > aggregationMap[key].estimated_one_rep_max) {
      aggregationMap[key].estimated_one_rep_max = estimatedOneRepMax;
    }
  });

  for (const key in aggregationMap) {
    const dateParts = key.split('/');

    let dateKey;
    if (interval === 'monthly') {
      let month = parseInt(dateParts[0]) - 1;
      let year = parseInt(dateParts[1]);
      dateKey = new Date(year, month, 1).getTime();
    } else {
      let month = parseInt(dateParts[0]) - 1;
      let day = parseInt(dateParts[1]);
      let year = parseInt(dateParts[2]);
      dateKey = new Date(year, month, day).getTime();
    }

    aggregatedData.push({
      created_date: dateKey,
      weight: aggregationMap[key].weight,
      estimated_one_rep_max: aggregationMap[key].estimated_one_rep_max
    });
  }

  // Sort by date
  aggregatedData.sort((a, b) => a.created_date - b.created_date);

  return aggregatedData;
};

const MaxRepChart = ({ lifts, dateInterval, repTarget }) => {
  const theme = useTheme();

  const lineColor = theme.colors.primary[500];
  const estimatedMaxColor = theme.colors.coolGray[300];

  const gridLineColor = theme.colors.gray[200];
  const tickColor = theme.colors.gray[300];

  const targetLifts = lifts.filter(l => (repTarget ? l.reps === parseInt(repTarget, 10) : true))

  const aggregatedData = aggregateMaxRepData(targetLifts, dateInterval);

  const minY = Math.min(...aggregatedData.map((item) => item.weight)) * 0.95;
  const maxY = Math.max(...aggregatedData.map((item) => item.weight)) * 1.05;

  const yTicks = generateYticks(minY, maxY);

  const minX = aggregatedData[0]?.created_date;
  const maxX = aggregatedData[aggregatedData.length - 1]?.created_date;
  const xTicks = generateXTicks(minX, maxX, dateInterval);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={aggregatedData}
        margin={{ top: 5, right: -25, left: 0, bottom: 5 }}
      >
        <CartesianGrid stroke={gridLineColor} strokeDasharray="none" strokeOpacity={0.15} />
        <XAxis
          dataKey="created_date"
          height={60}
          tickFormatter={tick => dateFormatter(tick, dateInterval)}
          tick={{ fontSize: 12, fill: tickColor, dy: 5 }}
          tickLine={false}
          type='number'
          scale='time'
          domain={[Math.min(...xTicks), Math.max(...xTicks)]}
          interval='preserveStart'
          ticks={xTicks}
          stroke={gridLineColor}
          strokeOpacity={0.3}
        />
        <YAxis
          orientation="right"
          domain={[Math.min(...yTicks), Math.max(...yTicks)]}
          tickLine={false}
          axisLine={false}
          ticks={yTicks}
          tick={{ fontSize: 12, fill: tickColor }}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: theme.colors.primary[900],
            borderColor: theme.colors.gray[500],
          }}
          labelStyle={{
            color: theme.colors.gray[200],
          }}
          itemStyle={{
            color: theme.colors.gray[200],
          }}
          labelFormatter={label => dateFormatter(label, dateInterval)}
        />
        <Line
          type="monotone"
          dataKey="weight"
          stroke={lineColor}
          strokeWidth={2}
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="estimated_one_rep_max"
          name="est. 1RM"
          stroke={estimatedMaxColor}
          strokeWidth={1}
          strokeDasharray="5 4"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const aggregateVolumeByInterval = (data, interval) => {
  const aggregatedData = [];
  const aggregationMap = {};

  data.forEach(item => {
    const date = new Date(item.created_date);
    let key;

    switch (interval) {
      case 'daily':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'weekly':
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        key = startOfWeek.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
      case 'monthly':
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' });
        break;
      default:
        key = date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
        break;
    }

    if (!aggregationMap[key]) {
      aggregationMap[key] = { volume: 0 };
    }

    aggregationMap[key].volume += item.weight * item.reps;
  });

  for (const key in aggregationMap) {
    const dateParts = key.split('/');

    let dateKey;
    if (interval === DATE_INTERVAL.MONTHLY) {
      // ['5', '2024']
      let month = parseInt(dateParts[0]) - 1;
      let year = parseInt(dateParts[1]);
      dateKey = new Date(year, month, 1).getTime();
    } else {
      // ['5', '21', '2024']
      let month = parseInt(dateParts[0]) - 1;
      let day = parseInt(dateParts[1])
      let year = parseInt(dateParts[2]);
      dateKey = new Date(year, month, day).getTime();
    }

    aggregatedData.push({
      created_date: dateKey,
      volume: aggregationMap[key].volume
    });
  }

  // Sort by date
  aggregatedData.sort((a, b) => a.created_date - b.created_date);

  return aggregatedData;
};

const roundThousands = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num;
};

const VolumeChart = ({ lifts, dateInterval }) => {
  const theme = useTheme();
  const lineColor = theme.colors.primary[500];
  const gridLineColor = theme.colors.gray[200];
  const tickColor = theme.colors.gray[300];

  const aggregatedData = aggregateVolumeByInterval(lifts, dateInterval);

  const minX = aggregatedData[0]?.created_date;
  const maxX = aggregatedData[aggregatedData.length - 1]?.created_date;
  const xTicks = generateXTicks(minX, maxX, dateInterval);

  const minY = 0;
  const maxY = Math.max(...aggregatedData.map((item) => item.volume)) * 1.05;
  const yTicks = generateYticks(minY, maxY);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={aggregatedData}
        margin={{ top: 5, right: -20, left: 0, bottom: 5 }}
      >
        <CartesianGrid stroke={gridLineColor} strokeDasharray="none" strokeOpacity={0.15} />
        <XAxis
          dataKey="created_date"
          height={60}
          tickFormatter={tick => dateFormatter(tick, dateInterval)}
          tick={{ fontSize: 12, fill: tickColor, dy: 5 }}
          tickLine={false}
          type="number"
          scale="time"
          domain={[Math.min(...xTicks), Math.max(...xTicks)]}
          ticks={xTicks}
          stroke={gridLineColor}
          strokeOpacity={0.3}
        />
        <YAxis
          orientation="right"
          domain={[Math.min(...yTicks), Math.max(...yTicks)]}
          tickLine={false}
          axisLine={false}
          ticks={yTicks}
          tick={{ fontSize: 12, fill: tickColor }}
          tickFormatter={roundThousands}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: theme.colors.primary[900],
            borderColor: theme.colors.gray[500],
          }}
          labelStyle={{
            color: theme.colors.gray[200],
          }}
          itemStyle={{
            color: theme.colors.gray[200],
          }}
          labelFormatter={label => dateFormatter(label, dateInterval)}
        />
        <Line
          type="monotone"
          dataKey="volume"
          stroke={lineColor}
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default ChartPage;
