

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// TODO: RENAME THESE
export const API_ENDPOINTS = {
  GET_EXAMPLE_DATA: `${API_BASE_URL}/example`,
  POST_CREATE_ACCOUNT: `${API_BASE_URL}/create-account`,
  POST_CREATE_GUEST: `${API_BASE_URL}/create-guest`,
  POST_AUTO_CREATE_WORKOUT: `${API_BASE_URL}/auto-workout`,
  POST_UPLOAD_VIDEO_URL: `${API_BASE_URL}/upload-video-url`,
};
