
export function groupLiftsByDate(lifts) {
  const groupedData = lifts.reduce((acc, current) => {
    const date = formatDate(new Date(current.created_at));
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(current);
    return acc;
  }, {});

  for (let date in groupedData) {
    groupedData[date].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }
  return groupedData;
}

function formatDate(date) {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const barWeight = 45
const plates = [
  45,
  25,
  10,
  5,
  2.5,
  1.25,
  .75
]
export function plateMath(weight) {
  var targetWeight = (weight - barWeight) / 2;

  // Create an array to hold the plate objects
  const plateObjects = [{ weight: barWeight, count: 0.5, type: 'bar' }];

  // Loop through the plates and calculate the number of each weight needed
  for (let i = 0; i < plates.length; i++) {
    const plateWeight = plates[i];
    let plateCount = 0;

    while (targetWeight >= plateWeight) {
      targetWeight -= plateWeight;
      plateCount++;
    }

    if (plateCount > 0) {
      plateObjects.push({ weight: plateWeight, count: plateCount, type: 'plate' });
    }
  }
  return plateObjects;
}

export function isDevUser() {
  const isDev = localStorage.getItem('simpleliftingapp.isDev');
  return isDev === 'true';
}


export function parseUserInput(input, liftTypes) {
  if (!input) {
    return
  }
  const sanitzedInput = input.toLowerCase();

  const lifts1 = parseSetXRep(sanitzedInput, liftTypes);

  if (lifts1 !== null) {
    return [lifts1];
  }

  const lifts2 = parseLiftInputWithSlash(sanitzedInput, liftTypes);
  if (lifts2 && lifts2.length === 0) {
    return
  }
  return lifts2;
}

export function parseUserInput2(input, liftTypes) {
  if (!input) {
    return [];
  }

  const parts = input.toLocaleLowerCase().split(/\s+/);

  // first word should be short name
  const shortName = parts.shift();

  if (parts.length < 1) {
    return [];
  }

  if (parts.every(part => part.includes('/'))) {
    return parseLiftInputWithSlash2(shortName, parts, liftTypes);
  }

  if (parts.length === 4 && parts[2].toLowerCase() === 'x') {
    return parseSetXRep2(shortName, parts, liftTypes);
  }

  return [];
}

function parseLiftInputWithSlash2(shortName, parts, liftTypes) {
  const liftType = liftTypes.find(type => type.short_name === shortName);

  // If no valid lift type is found, return an empty array
  if (!liftType) {
    return [];
  }

  const lifts = [];

  for (let part of parts) {
    const splitParts = part.split('/');
    if (splitParts.length !== 2) {
      return [];
    }

    const [weight, repsAndSets] = splitParts;
    const weightFloat = parseFloat(weight);
    if (isNaN(weightFloat)) {
      return [];
    }

    const repsSets = repsAndSets.split(',');
    if (repsSets.length === 0 || repsSets.some(rep => !isValidInteger(rep) || parseInt(rep, 10) < 1)) {
      return [];
    }

    for (const reps of repsSets) {
      const repsInt = parseInt(reps, 10);

      // Validate the reps; if it's not a valid number, return an empty array
      if (isNaN(repsInt)) {
        return [];
      }

      lifts.push({
        'lift': liftType.name,
        'lift_type_id': liftType.id,
        'weight': weightFloat,
        'sets': 1, // Assuming each entry is one set
        'reps': repsInt,
      });
    }
  };

  return lifts;
}

function isValidInteger(str) {
  return /^-?\d+$/.test(str);
}

// parses parts of ['200', '3', 'x', '5']
function parseSetXRep2(shortName, parts, liftTypes) {
  const weightFloat = parseFloat(parts[0]);
  const setInt = parseInt(parts[1], 10);
  // Ignore the 'x'
  const repsInt = parseInt(parts[3], 10);

  // Find the lift type by matching the shortName
  const liftType = liftTypes.find(type => type.short_name.toLocaleLowerCase() === shortName.toLocaleLowerCase());

  if (!liftType) {
    return [];
  }
  if (isNaN(weightFloat) || isNaN(setInt) || isNaN(repsInt)) {
    return [];
  }

  return [{
    'lift': liftType.name,
    'lift_type_id': liftType.id,
    'weight': weightFloat,
    'sets': setInt,
    'reps': repsInt,
  }];
}

const shorthand = {
  'bp': 'bench press',
  'dl': 'deadlift',
  'sq': 'squat',
  'ohp': 'overhead press'
}

export function getShortHand(liftType) {
  for (let key in shorthand) {
    if (shorthand[key] === liftType) {
      return key;
    }
  }

  // Optionally, return null or undefined if the liftType is not found
  return null;
}

export function getLiftFromShortHand(shortName) {
  return shorthand[shortName];
}

// Gets lift type id from free form input text
export function extractLift(input, liftTypes) {
  const words = input.toLowerCase().split(" ");
  if (!words) {
    return undefined;
  }

  const word = words[0];

  for (const liftType of liftTypes) {
    if (word === liftType.short_name.toLocaleLowerCase() || word === liftType.name.toLocaleLowerCase()) {
      return liftType.id;
    }
  }
  return undefined;
}


export function parseSetXRep(input, liftTypes) {
  // Use regex to extract lift, weight, sets, and reps

  const matches = input.match(/(\w+)\s+(\d+(\.\d+)?)\s+(\d+)\s*x\s*(\d+)/i);

  if (!matches) {
    return null; // Return null or handle the error if the input format is incorrect
  }

  // Extract the lift, weight, sets, and reps using destructuring
  const [, shortLift, weight, , sets, reps] = matches;

  if (!shortLift) {
    return;
  }

  var liftType;
  liftTypes.forEach(lt => {
    if (lt.short_name.toLocaleLowerCase() === shortLift.toLocaleLowerCase()) {
      liftType = lt;
    }
  })
  if (!liftType) {
    return null;
  }

  const setInput = parseInt(sets, 10);
  if (setInput > 10) {
    alert('Not allowed to create more than 10 sets at a time');
    return null;
  }

  const weightFloat = parseFloat(weight);

  // Return the parsed object
  return {
    'lift': liftType.name,
    'lift_type_id': liftType.id,
    'weight': weightFloat,
    'sets': setInput,
    'reps': parseInt(reps, 10)
  };
}

export function isSetXRep(text) {
  // 3 words. last 2 word are numbers. one empty at the end.
  const regex = /^\S+\s\d+\s\d+\s$/;
  return regex.test(text);
}

function parseLiftInputWithSlash(input, liftTypes) {
  // Split by space to separate different sets
  const parts = input.split(/\s+/);

  const shortLift = parts[0];
  if (!shortLift) {
    return;
  }

  var liftType;
  liftTypes.forEach(lt => {
    if (lt.short_name.toLocaleLowerCase() === shortLift.toLocaleLowerCase()) {
      liftType = lt;
    }
  })
  if (!liftType) {
    return null;
  }

  const results = [];

  for (let i = 1; i < parts.length; i++) {
    const [weight, repsGroup] = parts[i].split('/');

    if (!weight || !repsGroup) {
      return null; // Invalid input, weight or reps missing
    }

    const repsArray = repsGroup.split(',').map(Number);

    for (const reps of repsArray) {
      if (isNaN(reps) || isNaN(weight)) {
        return null; // Invalid input, reps or weight not a number
      }

      results.push({
        'lift': liftType.name,
        'lift_type_id': liftType.id,
        'weight': parseInt(weight, 10),
        'sets': 1,
        'reps': reps
      });
    }
  }

  return results;
}

export function buildLiftsToCreate(lifts, date) {
  // This is to create ordering between sets
  // Add a small amount of time.
  let dateIndex = 0;

  const liftsToCreate = [];
  for (const lift of lifts) {
    for (let i = 0; i < lift.sets; i++) {

      const createDate = convertToLocalDateWithTime(date, dateIndex);
      dateIndex++;

      liftsToCreate.push({
        lift_type: lift.lift,
        lift_type_id: lift.lift_type_id,
        weight: lift.weight,
        reps: lift.reps,
        created_at: createDate,
      })
    }
  }
  return liftsToCreate;
}

export function convertToLocalDateWithTime(dateString, secondsToAdd) {
  // Split the string into parts
  let parts = dateString.split('-');

  // Extract the year, month, and day as integers
  let year = parseInt(parts[0], 10);
  let month = parseInt(parts[1], 10) - 1; // Subtract 1 because months are 0-indexed
  let day = parseInt(parts[2], 10);

  let now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();
  let seconds = now.getSeconds();
  let milliseconds = now.getMilliseconds();

  // Create a new Date object with the local time zone
  return new Date(year, month, day, hours, minutes, seconds + secondsToAdd, milliseconds);
}

export const todaysDate = () => {
  const today = new Date();
  return getDate(today);
}

export const getDate = (date) => {
  const year = date.getFullYear();
  // @ts-ignore
  const month = String(date.getMonth() + 1).padStart(2, '0');
  // @ts-ignore
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const getDateFromStr = (dateStr) => {
  const date = new Date(dateStr);
  return getDate(date);
}

// Converts to readable format.
export function formatDateString(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
}

export const formatSixDigitDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
