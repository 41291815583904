import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDataManager } from "../data/useDataManager";
import { capitalize } from "../utils/utils";
import { useNavBarContext } from "./nav/NavBarContext";
import { AddIcon } from "@chakra-ui/icons";
import { InputLabel, ModalButton, SwitchInput, TextInput } from "./common/ModalComponents";
import { StyledModal } from "./common/StyledModal";
import { ToastAlert } from "./common/ToastAlert";

function LiftTypeRow({ liftType, onClick }) {

  const nameColor = liftType.active ? 'gray.100' : 'gray.400';

  return <Box width="100%" h='lg' onClick={onClick}>
    <HStack
      pl={6}
      borderRadius="md"
      h='100%'
      alignItems="center"
    >
      <Text fontSize="sm" color={nameColor} >
        {capitalize(liftType.name)}
      </Text>
      <Text fontSize="sm" color='gray.400' >
        {liftType.short_name}
      </Text>
    </HStack>
    <Divider borderColor='gray.600' />
  </Box>
}


function AddLiftTypeButton({ onClick }) {
  return <Box h='lg' w='lg' display='flex' alignItems='center' justifyContent='center' p={4}>
    <IconButton
      aria-label="Options"
      icon={<AddIcon />}
      variant="unstyled"
      color="white"
      onClick={onClick}
    />
  </Box>
}

function LiftTypeModal({ selectedLiftType, isOpen, onClose, onLiftTypeCreate, onLiftTypeUpdate, loading, closeOnOverlayClick = true }) {
  const [liftType, setLiftType] = useState('');
  const [shortName, setShortName] = useState('');
  const [active, setActive] = useState(selectedLiftType?.active || true);

  const title = 'Lift Type'

  useEffect(() => {
    if (selectedLiftType) {
      setLiftType(selectedLiftType.name);
      setShortName(selectedLiftType.short_name);
      setActive(selectedLiftType.active);
    } else {
      setLiftType('');
      setShortName('');
      setActive(true);
    }
  }, [selectedLiftType]);

  const onLiftTypeChange = (e) => {
    setLiftType(e.target.value);
  }

  const onShortNameChange = (e) => {
    setShortName(e.target.value);
  }

  const onActiveChange = (e) => {
    setActive(!active);
  }

  const onLiftCreateButtonClicked = async (e) => {
    const err = await onLiftTypeCreate(liftType, shortName);
    if (!err) {
      onModalClose();
    }
  }

  const onLiftUpdateButtonClicked = async (e) => {
    const updatedLiftType = { ...selectedLiftType };
    if (!!liftType) {
      updatedLiftType.name = liftType.trim();
    }
    if (!!shortName) {
      updatedLiftType.short_name = shortName.trim().toLocaleLowerCase();
    }

    updatedLiftType.active = active;

    const err = await onLiftTypeUpdate(updatedLiftType);
    if (!err) {
      onModalClose();
    }
  }

  const onModalClose = () => {
    setLiftType('');
    setShortName('');
    onClose();
  }

  const invalidInput = !liftType || !shortName;

  return <StyledModal
    title={title}
    body={
      <>
        <FormControl isRequired>
          <Flex flexDirection="column" gap={4}>
            <Flex align="center">
              <InputLabel name='Name' />
              <TextInput
                value={liftType}
                onChange={onLiftTypeChange}
              />
            </Flex>
          </Flex>
        </FormControl>

        <FormControl isRequired>
          <Flex flexDirection="column" gap={4}>
            <Flex align="center">
              <InputLabel name='Short Name' />
              <TextInput
                value={shortName}
                onChange={onShortNameChange}
              />
            </Flex>
          </Flex>
        </FormControl>

        {!!selectedLiftType &&
          <>
            <FormControl mt={4}>
              <Flex flexDirection="column" gap={4}>
                <Flex align="center" justifyContent="space-between">
                  <InputLabel name='Active' />
                  <SwitchInput
                    isChecked={active}
                    onChange={onActiveChange}
                  />
                </Flex>
              </Flex>
            </FormControl>
          </>
        }
      </>
    }
    footer={
      !!selectedLiftType ?
        <ModalButton
          mr={0}
          color='gray.100'
          loadingBg='primary.800'
          bg='primary.600'
          text='Update'
          onClick={onLiftUpdateButtonClicked}
          isLoading={loading}
          isDisabled={loading || invalidInput}
        />
        : <ModalButton
          mr={0}
          color='gray.100'
          loadingBg='primary.800'
          bg='primary.600'
          text='Create'
          onClick={onLiftCreateButtonClicked}
          isLoading={loading}
          isDisabled={loading || invalidInput}
        />
    }
    isOpen={isOpen}
    onClose={onModalClose}
    closeOnOverlayClick={closeOnOverlayClick}
  />
}

function LiftTypeListPage() {
  const toast = useToast()
  const dataManager = useDataManager();

  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [selectedLiftType, setSelectedLiftType] = useState(undefined);

  const { setNavBarElement } = useNavBarContext();
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    setNavBarElement(<AddLiftTypeButton onClick={onOpen} />);
    return () => setNavBarElement(null);
  }, [setNavBarElement, isOpen, onOpen, onClose]);

  const handleCreateLiftType = async (name, shortName) => {
    var errMsg;
    if (!name) {
      errMsg = 'Lift Type needs a name';
    } else if (!shortName) {
      errMsg = 'Lift Type needs a short name';
    }
    const liftType = (name || '').trim().toLocaleLowerCase();
    if (liftType.length > 100) {
      errMsg = 'Lift type name is too long';
    }

    if (errMsg) {
      toast({
        title: 'Issue Creating Lift Type',
        status: 'error',
        position: 'top',
        duration: 2500,
        isClosable: false,
        render: () => <ToastAlert status='error' message={errMsg} />,
      });
      return errMsg;
    }

    setCreating(true);

    const error = await dataManager.createLiftType(liftType, shortName.trim());
    if (error) {
      errMsg = error;

      toast({
        title: 'Issue Creating Lift Type',
        status: 'error',
        position: 'top',
        duration: 2500,
        isClosable: false,
        render: () => <ToastAlert status='error' message={errMsg} />,
      });
      setCreating(false);
      return errMsg;
    }
    setCreating(false);
  }

  const handleUpdateLiftType = async (updatedLiftType) => {
    setUpdating(true);

    const error = await dataManager.updateLiftType(updatedLiftType);
    if (error) {
      toast({
        title: 'Issue Updating Lift Type',
        status: 'error',
        position: 'top',
        duration: 2500,
        isClosable: false,
        render: () => <ToastAlert status='error' message={error} />,
      });
      setUpdating(false);
      return error;

    }
    setUpdating(false);
  }

  const onRowClick = (liftType) => {
    setSelectedLiftType(liftType);
    onOpen();
  }

  const onModalClose = () => {
    setSelectedLiftType(undefined);
    onClose();
  }

  const { liftTypes } = dataManager;

  const sortedLiftTypes = liftTypes.slice().sort((a, b) => {
    return a.active === b.active ? 0 : a.active ? -1 : 1;
  });

  return <>
    <LiftTypeModal
      selectedLiftType={selectedLiftType}
      isOpen={isOpen}
      onClose={onModalClose}
      loading={creating || updating}
      onLiftTypeCreate={handleCreateLiftType}
      onLiftTypeUpdate={handleUpdateLiftType}
    />

    <Box pt={10}>
      {sortedLiftTypes.map(liftType => <LiftTypeRow key={liftType.id} liftType={liftType} onClick={() => onRowClick(liftType)} />)}
    </Box>
  </>
}

export default LiftTypeListPage;
